<script>
export default {
    methods: {
        // $createBase64Image(fileObject) {
        //     const reader = new FileReader();
        //         let base64 = null

        //         reader.onload = (e) => {
        //             base64 = e.target.result;
        //         };

        //         return reader.readAsBinaryString(fileObject)
        //         // console.log("file object", fileObject);

        //         // return base64
        // }
    }
}
</script>